import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import gsap from "gsap";
import * as dat from "dat.gui";

import fragment from "../shaders/fragment.glsl";
import vertex from "../shaders/vertex.glsl";

export default class Sketch {
  constructor(options) {
    this.canvas = document.querySelector(".canvas");

    this.settings = {
      height: window.innerHeight,
      width: window.innerWidth
    };

    this.time = 0;
    this.materials = [];
    this.allMeshes = [];

    this.addCamera();
    this.addRenderer();

    this.resize();
    this.mergeHtmlWebGl();
    this.setupResize();
    this.addControls();

    this.render();
  }

  addControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
  }

  addRenderer() {
    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.canvas,
      alpha: true
    });
    this.renderer.setSize(this.settings.width, this.settings.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio), 2);

    // this.canvas.appendChild(this.renderer.domElement);
  }

  addCamera() {
    // this.camera = new THREE.PerspectiveCamera(
    //   45,
    //   this.settings.width / this.settings.height,
    //   1.0,
    //   2000
    // );
    // this.camera.position.z = 800;

    this.camera = new THREE.PerspectiveCamera(
      45,
      this.settings.width / this.settings.height,
      1.0,
      2000
    );
    this.camera.position.z = 50;
  }

  getFOV() {
    let height = this.settings.height;

    let fov = Math.atan(height / (2 * this.camera.position.z)) * 2;

    return (fov / Math.PI) * 180;
  }

  addObjects(vertex, fragment) {
    this.geometry = new THREE.PlaneBufferGeometry(
      this.settings.width,
      this.settings.height,
      100,
      100
    );

    this.material = new THREE.ShaderMaterial({
      wireframe: false,
      transparent: true,
      uniforms: {
        iResolution: { value: new THREE.Vector3() },
        iTime: { value: this.time },
        resolution: { value: new THREE.Vector2() },

        // color1: { value: new THREE.Vector3(0.776, 0.181, 0.424) },
        // color2: { value: new THREE.Vector3(0.269, 0, 0.457) },
        // color3: { value: new THREE.Vector3(0.148, 0.357, 0.71) },
        // color4: { value: new THREE.Vector3(0.479, 0.379, 0.633) },

        // color1: { value: new THREE.Vector3(0.799, 0.192, 0.534) },
        // color2: { value: new THREE.Vector3(0.81, 0, 0.6) },
        // color3: { value: new THREE.Vector3(1, 0.446, 0.743) },
        // color4: { value: new THREE.Vector3(1, 0.258, 0) }

        color1: { value: new THREE.Vector3(0.17, 0.368, 0.6) },
        color2: { value: new THREE.Vector3(0.148, 0.413, 0.6) },
        color3: { value: new THREE.Vector3(0.071, 0.402, 0.622) },
        color4: { value: new THREE.Vector3(0.225, 0.457, 0.644) }
      },
      vertexShader: vertex,
      fragmentShader: fragment
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
  }

  /**
   * Create a shader material
   *  Add uniforms to the material so those can be manipulated with webgl
   * Get all the website images
   *  Load their textures
   *  Create a geometry and material for them
   * Mouse hover/ leave interactions
   * Set meshes position and scaling according to the images position in the website
   */
  mergeHtmlWebGl() {
    this.material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        uImage: { value: 0 },
        hover: { value: new THREE.Vector2(0.5, 0.5) },
        hoverState: {
          value: 0
        }
      },
      fragmentShader: fragment,
      vertexShader: vertex
    });

    const htmlImages = [...document.querySelectorAll("img")];

    htmlImages.forEach(image => {
      let texture = new THREE.Texture(image);
      texture.needsUpdate = true;
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      let geometry = new THREE.PlaneBufferGeometry(1, 1, 10, 10);
      let material = this.material.clone();

      console.log(image.getBoundingClientRect().x);

      this.materials.push(material);
      material.uniforms.uImage.value = texture;

      image.addEventListener("mouseenter", () => {
        gsap.to(material.uniforms.hoverState, {
          duration: 1,
          value: 1,
          ease: "expo.out"
        });
      });

      image.addEventListener("mouseout", () => {
        gsap.to(material.uniforms.hoverState, {
          duration: 1,
          value: 0,
          ease: "expo.out"
        });
      });

      let mesh = new THREE.Mesh(geometry, material);
      mesh.userData.image = image;

      this.allMeshes.push(mesh);

      this.setScalePosition(mesh);
      this.scene.add(mesh);
    });
  }

  /**
   * Set scale property for each mesh that exists inside three js scene
   * @param {Object} mesh each object mesh
   */
  setScale(mesh) {
    let image = mesh.userData.image;
    let bounds = image.getBoundingClientRect();

    mesh.scale.set(bounds.width, bounds.height, 1);
  }

  /**
   * Set the correct mesh position according to each image position in the website
   * @param {Object} mesh each object mesh
   */
  setPosition(mesh) {
    let image = mesh.userData.image;
    let bounds = image.getBoundingClientRect();

    mesh.position.set(
      bounds.left - this.settings.width / 2 + bounds.width / 2,
      -bounds.top - bounds.height / 2 + this.settings.height / 2
    );
  }

  /**
   * Scale + position calls
   * @param {Object} mesh each object mesh
   */
  setScalePosition(mesh) {
    this.setScale(mesh);
    this.setPosition(mesh);
  }

  resize() {
    this.settings.height = window.innerHeight;
    this.settings.width = window.innerWidth;

    this.camera.fov = this.getFOV();
    this.camera.aspect = this.settings.width / this.settings.height;
    this.camera.updateProjectionMatrix();

    this.allMeshes.forEach(mesh => {
      this.setScalePosition(mesh);
    });

    this.renderer.setSize(this.settings.width, this.settings.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  render() {
    this.time += 0.05;
    this.material.uniforms.time.value = this.time;
    // this.mesh.rotation.x = this.time * 0.025;
    // this.mesh.rotation.y = this.time * 0.025;

    this.materials.forEach(material => {
      material.uniforms.time.value = this.time;
    });

    this.allMeshes.forEach(mesh => {
      this.setScalePosition(mesh);
    });

    this.renderer.render(this.scene, this.camera);

    requestAnimationFrame(this.render.bind(this));
  }
}

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import gsap from "gsap";
import * as dat from "dat.gui";

export default class Background {
  constructor(options) {
    this.canvas = document.querySelector(".creative__webgl");

    this.settings = {
      height: window.innerHeight,
      width: window.innerWidth
    };

    this.time = 0;

    this.addCamera();
    this.addRenderer();

    this.resize();
    this.setupResize();
    this.addControls();

    this.addObjects(options.vertex, options.fragment);
    this.addGui();

    this.render();
  }

  addGui() {
    this.gui = new dat.GUI();

    this.colorOneFolder = this.gui.addFolder("Color 1");
    this.colorTwoFolder = this.gui.addFolder("Color 2");
    this.colorThreeFolder = this.gui.addFolder("Color 3");
    this.colorFourFolder = this.gui.addFolder("Color 4");

    this.colorOneFolder
      .add(this.material.uniforms.color1.value, "x")
      .step(0.001)
      .min(0)
      .max(1)
      .name("red");
    this.colorOneFolder
      .add(this.material.uniforms.color1.value, "y")
      .step(0.001)
      .min(0)
      .max(1)
      .name("green");
    this.colorOneFolder
      .add(this.material.uniforms.color1.value, "z")
      .step(0.001)
      .min(0)
      .max(1)
      .name("blue");

    this.colorTwoFolder
      .add(this.material.uniforms.color2.value, "x")
      .step(0.001)
      .min(0)
      .max(1)
      .name("red");
    this.colorTwoFolder
      .add(this.material.uniforms.color2.value, "y")
      .step(0.001)
      .min(0)
      .max(1)
      .name("green");
    this.colorTwoFolder
      .add(this.material.uniforms.color2.value, "z")
      .step(0.001)
      .min(0)
      .max(1)
      .name("blue");

    this.colorThreeFolder
      .add(this.material.uniforms.color3.value, "x")
      .step(0.001)
      .min(0)
      .max(1)
      .name("red");
    this.colorThreeFolder
      .add(this.material.uniforms.color3.value, "y")
      .step(0.001)
      .min(0)
      .max(1)
      .name("green");
    this.colorThreeFolder
      .add(this.material.uniforms.color3.value, "z")
      .step(0.001)
      .min(0)
      .max(1)
      .name("blue");

    this.colorFourFolder
      .add(this.material.uniforms.color4.value, "x")
      .step(0.001)
      .min(0)
      .max(1)
      .name("red");
    this.colorFourFolder
      .add(this.material.uniforms.color4.value, "y")
      .step(0.001)
      .min(0)
      .max(1)
      .name("green");
    this.colorFourFolder
      .add(this.material.uniforms.color4.value, "z")
      .step(0.001)
      .min(0)
      .max(1)
      .name("blue");
  }

  addControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
  }

  addRenderer() {
    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.renderer.setSize(this.settings.width, this.settings.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio), 2);

    this.canvas.appendChild(this.renderer.domElement);
  }

  addCamera() {
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.settings.width / this.settings.height,
      1.0,
      2000
    );
    this.camera.position.z = 800;
  }

  getFOV() {
    let height = this.settings.height;

    let fov = Math.atan(height / (2 * this.camera.position.z)) * 2;

    return (fov / Math.PI) * 180;
  }

  addObjects(vertex, fragment) {
    this.geometry = new THREE.PlaneBufferGeometry(
      this.settings.width,
      this.settings.height,
      100,
      100
    );

    this.material = new THREE.ShaderMaterial({
      wireframe: false,
      transparent: true,
      uniforms: {
        iResolution: { value: new THREE.Vector3() },
        iTime: { value: this.time },
        resolution: { value: new THREE.Vector2() },
        // GAMIFICATION
        // color1: { value: new THREE.Vector3(0, 0.446, 0.335) },
        // color2: { value: new THREE.Vector3(0, 0.313, 0.247) },
        // color3: { value: new THREE.Vector3(0, 0.435, 0.567) },
        // color4: { value: new THREE.Vector3(0, 0.468, 0.39) }

        color1: { value: new THREE.Vector3(0.799, 0.192, 0.534) },
        color2: { value: new THREE.Vector3(0.81, 0.0, 0.6) },
        color3: { value: new THREE.Vector3(1, 0.446, 0.743) },
        color4: { value: new THREE.Vector3(1, 0.258, 0.0) }
      },
      vertexShader: vertex,
      fragmentShader: fragment
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
  }

  resize() {
    this.settings.height = window.innerHeight;
    this.settings.width = window.innerWidth;

    // this.camera.fov = this.getFOV();
    this.camera.aspect = this.settings.width / this.settings.height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.settings.width, this.settings.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  render() {
    this.time += 0.05;
    this.material.uniforms.iTime.value = this.time;

    this.renderer.render(this.scene, this.camera);

    requestAnimationFrame(this.render.bind(this));
  }
}

import Page from "../classes/Page";

export default class Creative extends Page {
  constructor() {
    super({
      id: "shared",
      element: ".shared",
      elements: {
        title: document.querySelector(".page__title"),
        subtitle: document.querySelector(".page__subtitle"),
        label: document.querySelector(".page__information__label"),
        line: document.querySelector(".page__information__line"),
        link: document.querySelector(".page__information__link"),
        navigation: document.querySelectorAll(".navigation__link")
      }
    });
  }
}

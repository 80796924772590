import { gsap } from "gsap";
import Animation from "../classes/Animation.js";

export default class Line extends Animation {
  constructor({ element, elements }) {
    super({
      element,
      elements
    });
  }

  animateIn() {
    gsap.set(this.element, {
      autoAlpha: 1
    });

    gsap.fromTo(
      this.element,
      {
        scaleX: 0,
        transformOrigin: "0 0"
      },
      { scaleX: 1, delay: 0.75, ease: "expo.out", duration: 1.5 }
    );
  }

  animateOut() {
    gsap.set(this.element, {
      autoAlpha: 0
    });
  }
}

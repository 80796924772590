import Sketch from "./canvas/scene";
import Background from "./canvas/background";
import BackgroundG from "./canvas/backgroundG";
import Preloader from "./components/Preloader";
import Shared from "./pages/shared";
import SplitText from "./utils/SplitText.min";

import sharedVertex from "./shaders/shared/vertex.glsl";
import sharedFragment from "./shaders/shared/fragment.glsl";
import Scroll from "./components/Scroll";

import barba from "@barba/core";
import gsap from "gsap";
import Creative from "./pages/creative";
import Gamification from "./pages/gamification";
import Transportation from "./pages/transportation";

class App {
  constructor() {
    this.pages = {};

    this.createContent();

    this.createPreloader();
    this.createPages();

    this.customScroll();
    this.pageTransitions();
  }

  /**
   * Set up the page content and template
   */
  createContent() {
    this.content = document.querySelector(".page");

    this.template = this.content.getAttribute("data-template");
  }

  createPreloader() {
    this.preloader = new Preloader();

    this.preloader.once("completed", this.onPreloaded.bind(this));
  }

  onPreloaded() {
    this.preloader.destroy();

    this.page.animateInAfterPreloader();
  }

  /**
   * Creates the pages. Instantiates its Classes
   */
  createPages() {
    this.pages = {
      shared: new Shared(),
      creative: new Creative(),
      gamification: new Gamification(),
      transportation: new Transportation()
    };

    this.page = this.pages[this.template];

    this.page.create();

    // remove this
    // this.page.animateInAfterPreloader();

    this.page.positionAnimations();
    // this.page.animateIn();

    this.onResize();

    if (document.querySelector(".shared__webgl")) {
      this.background = new Background({
        canvas: ".shared__webgl",
        vertex: sharedVertex,
        fragment: sharedFragment
      });
    } else if (document.querySelector(".creative__webgl")) {
      this.background = new Background({
        canvas: ".creative__webgl",
        vertex: sharedVertex,
        fragment: sharedFragment
      });
    } else if (document.querySelector(".gamification__webgl")) {
      this.background = new Background({
        canvas: ".gamification__webgl",
        vertex: sharedVertex,
        fragment: sharedFragment
      });
    } else if (document.querySelector(".transportation__webgl")) {
      this.background = new Background({
        canvas: ".transportation__webgl",
        vertex: sharedVertex,
        fragment: sharedFragment
      });
    }
  }

  customScroll() {
    this.smoothScroll = new Scroll({
      template: document.querySelector(".scrollable"),
      wrapper: document.getElementById("main-wrapper")
    });
  }

  onResize() {
    if (this.page && this.page.onResize) {
      this.page.onResize();
    }
  }

  pageTransitions() {
    let that = this;
    // this.webgl = document.querySelector(".webgl");

    this.title = new SplitText(document.querySelector(".curtain__title"), {
      type: "lines",
      linesClass: "b"
    });
    new SplitText(document.querySelector(".curtain__title"), {
      type: "lines",
      linesClass: "a"
    });

    barba.init({
      transitions: [
        // shared-gamification
        {
          name: "shared-gamification",
          from: {
            namespace: ["shared"]
          },
          to: {
            namespace: ["gamification"]
          },
          leave(data) {
            this.timeline = gsap.timeline();

            return this.timeline
              .to(".curtain", {
                y: 0,
                ease: "expo.out",
                duration: 1
              })
              .fromTo(
                that.title.lines,
                {
                  yPercent: 100
                },
                {
                  yPercent: 0,
                  ease: "expo.out",
                  duration: 0.75,
                  stagger: 0.1
                },
                "-=0.5"
              )
              .to(that.title.lines, {
                yPercent: -100,
                ease: "expo.out",
                duration: 0.75,
                stagger: 0.1,
                delay: 0.5
              })
              .to(data.current.container, {
                opacity: 0
              });
          },
          once(data) {},
          enter(data) {
            // that.smoothScroll = new Scroll({
            //   template: [...data.next.container.children][0],
            //   wrapper: data.next.container
            // });

            let dataTemplate = [...data.next.container.children][0].children;

            that.content = [...dataTemplate][0];

            that.template = that.content.getAttribute("data-template");

            that.page = that.pages[that.template];

            that.page.create();

            that.background = new Background({
              canvas: ".gamification__webgl",
              vertex: sharedVertex,
              fragment: sharedFragment
            });

            that.onResize();

            window.scroll(0, 0);

            return gsap
              .timeline()
              .to(".curtain", {
                y: "-100%",
                ease: "expo.in",
                duration: 0.75
              })
              .from(
                data.next.container,
                {
                  opacity: 0
                },
                "-=2.4"
              );
          }
        },
        // gamification-shared
        {
          name: "gamification-shared",
          from: {
            namespace: ["gamification"]
          },
          to: {
            namespace: ["shared"]
          },
          leave(data) {
            this.timeline = gsap.timeline();

            return this.timeline
              .to(".curtain", {
                y: 0,
                ease: "expo.out",
                duration: 1
              })
              .fromTo(
                that.title.lines,
                {
                  yPercent: 100
                },
                {
                  yPercent: 0,
                  ease: "expo.out",
                  duration: 0.75,
                  stagger: 0.1
                },
                "-=0.5"
              )
              .to(that.title.lines, {
                yPercent: -100,
                ease: "expo.out",
                duration: 0.75,
                stagger: 0.1,
                delay: 0.5
              })
              .to(data.current.container, {
                opacity: 0
              });
          },
          once(data) {},
          enter(data) {
            // that.smoothScroll = new Scroll({
            //   template: [...data.next.container.children][0],
            //   wrapper: data.next.container
            // });

            let dataTemplate = [...data.next.container.children][0].children;

            that.content = [...dataTemplate][0];

            that.template = that.content.getAttribute("data-template");

            that.page = that.pages[that.template];

            that.page.create();

            that.background = new Background({
              canvas: ".shared__webgl",
              vertex: sharedVertex,
              fragment: sharedFragment
            });

            that.onResize();

            window.scroll(0, 0);

            return gsap
              .timeline()
              .to(".curtain", {
                y: "-100%",
                ease: "expo.in",
                duration: 0.75
              })
              .from(
                data.next.container,
                {
                  opacity: 0
                },
                "-=2.4"
              );
          }
        },
        // gamification-transportation
        {
          name: "gamification-transportation",
          from: {
            namespace: ["gamification"]
          },
          to: {
            namespace: ["transportation"]
          },
          leave(data) {
            this.timeline = gsap.timeline();

            return this.timeline
              .to(".curtain", {
                y: 0,
                ease: "expo.out",
                duration: 1
              })
              .fromTo(
                that.title.lines,
                {
                  yPercent: 100
                },
                {
                  yPercent: 0,
                  ease: "expo.out",
                  duration: 0.75,
                  stagger: 0.1
                },
                "-=0.5"
              )
              .to(that.title.lines, {
                yPercent: -100,
                ease: "expo.out",
                duration: 0.75,
                stagger: 0.1,
                delay: 0.5
              })
              .to(data.current.container, {
                opacity: 0
              });
          },
          once(data) {},
          enter(data) {
            // that.smoothScroll = new Scroll({
            //   template: [...data.next.container.children][0],
            //   wrapper: data.next.container
            // });

            let dataTemplate = [...data.next.container.children][0].children;

            that.content = [...dataTemplate][0];

            that.template = that.content.getAttribute("data-template");

            that.page = that.pages[that.template];

            that.page.create();

            that.background = new Background({
              canvas: ".transportation__webgl",
              vertex: sharedVertex,
              fragment: sharedFragment
            });

            that.onResize();

            window.scroll(0, 0);

            return gsap
              .timeline()
              .to(".curtain", {
                y: "-100%",
                ease: "expo.in",
                duration: 0.75
              })
              .from(
                data.next.container,
                {
                  opacity: 0
                },
                "-=2.4"
              );
          }
        },
        // transportation-gamification
        {
          name: "transportation-gamification",
          from: {
            namespace: ["transportation"]
          },
          to: {
            namespace: ["gamification"]
          },
          leave(data) {
            this.timeline = gsap.timeline();

            return this.timeline
              .to(".curtain", {
                y: 0,
                ease: "expo.out",
                duration: 1
              })
              .fromTo(
                that.title.lines,
                {
                  yPercent: 100
                },
                {
                  yPercent: 0,
                  ease: "expo.out",
                  duration: 0.75,
                  stagger: 0.1
                },
                "-=0.5"
              )
              .to(that.title.lines, {
                yPercent: -100,
                ease: "expo.out",
                duration: 0.75,
                stagger: 0.1,
                delay: 0.5
              })
              .to(data.current.container, {
                opacity: 0
              });
          },
          once(data) {},
          enter(data) {
            // that.smoothScroll = new Scroll({
            //   template: [...data.next.container.children][0],
            //   wrapper: data.next.container
            // });

            let dataTemplate = [...data.next.container.children][0].children;

            that.content = [...dataTemplate][0];

            that.template = that.content.getAttribute("data-template");

            that.page = that.pages[that.template];

            that.page.create();

            that.background = new Background({
              canvas: ".gamification__webgl",
              vertex: sharedVertex,
              fragment: sharedFragment
            });

            that.onResize();

            window.scroll(0, 0);

            return gsap
              .timeline()
              .to(".curtain", {
                y: "-100%",
                ease: "expo.in",
                duration: 0.75
              })
              .from(
                data.next.container,
                {
                  opacity: 0
                },
                "-=2.4"
              );
          }
        },
        // transportation-creativeDev
        {
          name: "transportation-creative",
          from: {
            namespace: ["transportation"]
          },
          to: {
            namespace: ["creative"]
          },
          leave(data) {
            this.timeline = gsap.timeline();

            return this.timeline
              .to(".curtain", {
                y: 0,
                ease: "expo.out",
                duration: 1
              })
              .fromTo(
                that.title.lines,
                {
                  yPercent: 100
                },
                {
                  yPercent: 0,
                  ease: "expo.out",
                  duration: 0.75,
                  stagger: 0.1
                },
                "-=0.5"
              )
              .to(that.title.lines, {
                yPercent: -100,
                ease: "expo.out",
                duration: 0.75,
                stagger: 0.1,
                delay: 0.5
              })
              .to(data.current.container, {
                opacity: 0
              });
          },
          once(data) {},
          enter(data) {
            // that.smoothScroll = new Scroll({
            //   template: [...data.next.container.children][0],
            //   wrapper: data.next.container
            // });

            let dataTemplate = [...data.next.container.children][0].children;

            that.content = [...dataTemplate][0];

            that.template = that.content.getAttribute("data-template");

            that.page = that.pages[that.template];

            that.page.create();

            that.background = new Background({
              canvas: ".creative__webgl",
              vertex: sharedVertex,
              fragment: sharedFragment
            });

            that.onResize();

            window.scroll(0, 0);

            return gsap
              .timeline()
              .to(".curtain", {
                y: "-100%",
                ease: "expo.in",
                duration: 0.75
              })
              .from(
                data.next.container,
                {
                  opacity: 0
                },
                "-=2.4"
              );
          }
        },
        // creativeDev-transportation
        {
          name: "creative-transportation",
          from: {
            namespace: ["creative"]
          },
          to: {
            namespace: ["transportation"]
          },
          leave(data) {
            this.timeline = gsap.timeline();

            return this.timeline
              .to(".curtain", {
                y: 0,
                ease: "expo.out",
                duration: 1
              })
              .fromTo(
                that.title.lines,
                {
                  yPercent: 100
                },
                {
                  yPercent: 0,
                  ease: "expo.out",
                  duration: 0.75,
                  stagger: 0.1
                },
                "-=0.5"
              )
              .to(that.title.lines, {
                yPercent: -100,
                ease: "expo.out",
                duration: 0.75,
                stagger: 0.1,
                delay: 0.5
              })
              .to(data.current.container, {
                opacity: 0
              });
          },
          once(data) {
            let dataTemplate = [...data.next.container.children][0].children;

            that.content = [...dataTemplate][0];

            that.template = that.content.getAttribute("data-template");

            that.page = that.pages[that.template];

            that.page.animateIn();

            that.background = new Background({
              canvas: ".creative__webgl",
              vertex: sharedVertex,
              fragment: sharedFragment
            });

            that.onResize();
          },
          enter(data) {
            // that.smoothScroll = new Scroll({
            //   template: [...data.next.container.children][0],
            //   wrapper: data.next.container
            // });

            let dataTemplate = [...data.next.container.children][0].children;

            that.content = [...dataTemplate][0];

            that.template = that.content.getAttribute("data-template");

            that.page = that.pages[that.template];

            that.page.create();

            that.background = new Background({
              canvas: ".transportation__webgl",
              vertex: sharedVertex,
              fragment: sharedFragment
            });

            that.onResize();

            window.scroll(0, 0);

            return gsap
              .timeline()
              .to(".curtain", {
                y: "-100%",
                ease: "expo.in",
                duration: 0.75
              })
              .from(
                data.next.container,
                {
                  opacity: 0
                },
                "-=2.4"
              );
          }
        }
      ]
    });
  }
}

new App();
